import React, { Component } from 'react';
import './App.css';
import './index.css'
import './bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from './components/Core/ThemeContext';
import Banner from './components/Core/Banner';
import InputGrid from './components/Input/InputGrid';
import { SubheaderText, SmallGutter, HeaderText, LargeGutter } from './components/Core/TextComponents';
import { DailyLocalStorageStore, LocalStorage } from './components/Storage/LocalStorage';
import { Button } from 'react-bootstrap';
import AddShortcut from './components/Input/AddShortcut';
import DaySwitch from './components/Input/DaySwitch';
import IconGrid from './components/Input/IconGrid';
import { Home, Settings, Info, Help } from '@material-ui/icons';
import { Fastfood, Restaurant, LocalDining } from '@material-ui/icons';
import { FitnessCenter, LineWeight, AccessAlarm } from '@material-ui/icons';
import { AddCircle, RemoveCircle, Delete, FiberManualRecord } from '@material-ui/icons';
import { Search, SearchOff, FilterList } from '@material-ui/icons';


class Labs extends Component {
    static contextType = ThemeContext;

    icons = [Search, Fastfood, LineWeight, AddCircle, Help, Delete]; // Icons passed in
    labels = ["bible search", "calories", "weight", "counter", "help", "delete data"];
    targets = ["/search", "/labs/calories", "/labs/weight", "/labs/counter", "/help", "/labs/deleteData"]

    handleIconClick = (index) => {
        const target = this.targets[index];
        console.log(`Icon ${index} clicked ${target} `);
        window.location = target;
    };

    constructor() {
        super();
        this.handleIconClick = this.handleIconClick.bind(this)
    }

    render() {
        return (
            <>
                <Banner text="Experimental apps as stepping stones towards greater functionality for ai-Bible"></Banner>
                <div style={{minHeight:'400px', height:'100vh',margin:'auto', padding: '20px'}} className="graphpaper">
                    <IconGrid
                        icons={this.icons}
                        labels={this.labels}
                        onIconClick={this.handleIconClick}                        
                    />
                </div>
                
            </>
        );
    }
}

export default Labs;