import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, IconButton } from '@material-ui/core';
import { IconTitle, BodyText } from '../Core/TextComponents';

const useStyles = makeStyles((theme) => ({
    iconButton: {
      width: 60,
      height: 20,
      margin: 20,
      '&:hover': {
        backgroundColor: '#DDD',  // Change icon color on hover (you can choose any color here)
        Color: '#EEE'
      },
    },
    icon: {
      fontSize: 40, // Set the size of the icon
    },
  }));

// Takes a list of icons and a callback which receives an index of the icon clicked
const IconGrid = ({ icons, labels, onIconClick }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justifyContent="left" style={{ flexWrap: 'wrap' }}>
      {icons.map((IconComponent, index) => (
        <Grid item key={index} xs="auto">
          <IconButton
            onClick={() => onIconClick(index)} // Pass the index or the icon to callback
            // style={{ width: 40, height: 40, margin: 40 }} // Fixed size for each icon
            className={classes.iconButton}
          >
            <IconComponent style={{ fontSize: 50 }}/>
          </IconButton>
          <IconTitle>
            {labels[index]} {/* Label for each icon */}
          </IconTitle>
        </Grid>
      ))}
    </Grid>
  );
};

export default IconGrid;