import React from 'react';
import '../../index.css';

// Define some simple styles for the grid and buttons
const styles = (colorIndex, columns) => { return {
    grid: {
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, 1fr)`, // Adjust number to control grid width
      gap: '10px',
      padding: '5px 20px',
    },
    button: {
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      cursor: 'pointer',
      border: '1px solid #ccc',
      backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(`--tab-color-${colorIndex}`),
      transition: 'background-color 0.3s ease',
      color: '#f9f9f9',
      width: '100%'
      // maxWidth: '100px'
    },
    bigButton: {
      padding: '10px',
      margin: '20px',
      fontSize: '16px',
      borderRadius: '5px',
      cursor: 'pointer',
      width: '100%',
      border: '1px solid #ccc',
      backgroundColor: '#f9f9f9',
      transition: 'background-color 0.3s ease',
      // maxWidth: '100px'
    }
  }};

const InputGrid = ({ onSelect, color=1, columns = 5, numbers = [
  -1000, -100, -50, -10, -1,
  1, 5, 10, 50, 100, 
  150, 250, 400, 600, 1000
]}) => {
    return (
      <>
        <div style={styles(color,columns).grid}>
          {numbers.map((number) => (
            <button
              key={number}
              style={styles(color,columns).button}
              onClick={() => onSelect(number)}
            >
              {number}
            </button>
          ))}
        </div>
        {/* <div style={{display:'flex'}}>
          <button
            key={'Clear'}
            style={styles(color,columns).bigButton}
            onClick={() => onClear()}
          >
            {'Clear'}
          </button>
          <div style={{minWidth:'0px'}}>
          </div>
        </div> */}
        
      </>
    );
  };

  export default InputGrid;