import React, { Component } from 'react';
import '../../App.css';
import '../../bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from '../../components/Core/ThemeContext';
import Banner from '../../components/Core/Banner';
import InputGrid from '../../components/Input/InputGrid';
import { SubheaderText, SmallGutter, HeaderText, LargeGutter } from '../../components/Core/TextComponents';
import { DailyLocalStorageStore, LocalStorage } from '../../components/Storage/LocalStorage';
import { Button } from 'react-bootstrap';
import AddShortcut from '../../components/Input/AddShortcut';
import DaySwitch from '../../components/Input/DaySwitch';
import WebOnly from '../../components/Conditional/WebOnly';

const queryParams = new URLSearchParams(window.location.search);
const caloriesParam = parseInt(queryParams.get('calories'));

const storage = new DailyLocalStorageStore('aib-calories-log');
const unitStorage = new LocalStorage('aib-calories-units');

const CALORIES_PER_KJ = 4.184;

class CalorieCounter extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        let total = 0;
        const offset = 0;

        const today = storage.getToday();

        let unit = unitStorage.get('cal');
        //if (unit) unit = unit;

        if (today)
            total = today.calories;

        this.state = {
            contacts: [],
            search: 'John.12.1-John.12.11',
            previoussearch: 'John.12.1-John.12.11',
            totalCals: total,
            unit,
            offset,
        };
    }

    

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        if (caloriesParam)
            this.handleSelect(caloriesParam);

        window.addEventListener('storage', this.handleStorageChange);

        this.context.loading(false);
    }

    setOffset = (offset) => {
       
        let totalCals = 0;

        console.log({'yesterday': storage.getYesterday()})

        if (offset == 0)
            totalCals = storage.getToday()?.calories;

        if (offset < 0)
        {
            if (!storage.getForDate(offset))
            {
                storage.setForDate(offset, {'calories': 0});
            }
            totalCals = storage.getForDate(offset)?.calories;
        }

        this.setState({offset, totalCals});
    }

    handleStorageChange = (event) => {
        console.log('storage', event)
        try {
            if (this.state.calories != storage.getToday().calories)
                this.setState({ totalCals: storage.getToday().calories });
            console.log('updated')
        } catch (error) {
            
        }
        // if (event.key === 'syncedValue') {
        //   this.setState({ value: event.newValue || '' });
        // }
    };

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    handleChange = (event) => {
        this.setState({ search: event.target.value });
    };

    handleSelect = (number) => {
        let increment = number * ((this.state.unit == 'kJ')?1/CALORIES_PER_KJ:1);
        const newValue = this.state.totalCals + parseFloat(increment) || 0;
        console.log('Set calories: ' + this.state.totalCals + increment, this.state.unit, increment, number)
        storage.setForDate(this.state.offset, {'calories': newValue});
        this.setState({ totalCals: parseFloat(newValue) });
    };

    handleClear = (param) => {
        console.log('button', {param})
        if (param == 'clear')
        {
            const newValue = 0;
            console.log('Set calories: ' + 0)
            storage.setForDate(this.state.offset, {'calories': newValue});
            this.setState({ totalCals: parseFloat(newValue) });
        }
        else if (param == 'kJ')
        {
            if (this.state.unit == 'cal')
            {
                const cal = this.state.totalCals;
                const kj = cal * CALORIES_PER_KJ;
                unitStorage.set('kJ');
                this.setState({unit: 'kJ'});
            }
        }
        else if (param == 'cal')
        {
            if (this.state.unit == 'kJ')
            {
                const kj = this.state.totalCals;
                const cal = kj / CALORIES_PER_KJ;
                unitStorage.set('cal');
                this.setState({unit: 'cal'});
            }
        } 
    };

    render() {
        document.title = 'ai|Calories';
        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}
            >
                <WebOnly>
                    <Banner text="For experimental purposes only, uses cookies. Will not store data between sessions if you have private browsing enabled." />
                </WebOnly>
                
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText>Calorie Counter </HeaderText>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>Add to the number</SubheaderText>
                        <SmallGutter></SmallGutter>
                        <h1 style={{border:'solid 1px lightgrey', padding:'10px'}}><strong>{Math.ceil(parseFloat(this.state.totalCals)*((this.state.unit == "kJ")?CALORIES_PER_KJ:1)) + " " + this.state.unit}</strong></h1>
                    </div>
                    <InputGrid onSelect={this.handleSelect} color={1}/>
                    <InputGrid onSelect={this.handleClear} color={6} columns={3} numbers={['clear','kJ','cal']} />


                    <div style={{ margin: "0px 20px"}}>

                        <SmallGutter></SmallGutter>

                        <DaySwitch offset={this.state.offset} setOffset={this.setOffset}></DaySwitch>

                        <LargeGutter></LargeGutter>
                        <LargeGutter></LargeGutter>
                        <LargeGutter></LargeGutter>

                        {/* <a href='' target="_blank">
                            <Button fullWidth>Add to Apple Shortcuts</Button>
                        </a> */}
                        <WebOnly>
                            <AddShortcut shortcut="https://www.icloud.com/shortcuts/e3226a69f1514d809facdac36a3afd58"></AddShortcut>
                        </WebOnly>
                        
                    </div>
                </div>

                
            </div>
        );
    }
}

export default CalorieCounter;
