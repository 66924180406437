import React, { Component } from 'react';
import '../../App.css';
import '../../bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from '../../components/Core/ThemeContext';
import Banner from '../../components/Core/Banner';
import InputGrid from '../../components/Input/InputGrid';
import { SubheaderText, SmallGutter, HeaderText, LargeGutter } from '../../components/Core/TextComponents';
import { LocalStorage, DailyLocalStorageStore } from '../../components/Storage/LocalStorage';
import { Accordion, Card, Select, TextField, Typography} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Delete } from '@material-ui/icons';
import SelectWithMore from '../../components/Input/SelectWithMore';
import SelectWithGroup from '../../components/Input/SelectWithGroup';
import SelectAccordion from '../../components/Input/SelectAccordion';
import { Button } from 'react-bootstrap';
import StockRecipes from './StockRecipes.json';
import StockIngredients from './StockIngredients.json';
import SwipeListTwoWay from './SwipeListTwoWay';

const queryParams = new URLSearchParams(window.location.search);
const countParam = parseInt(queryParams.get('count'));
const ingredientsStorage = new LocalStorage('aib-ingredient'); // read from ingredients
const recipeStorage = new LocalStorage('aib-recipe'); // save list of ingredients (duplicate)
const storage = ingredientsStorage;
const caloriesStorage = new DailyLocalStorageStore('aib-calories-log');

const style = {
    formControl: {
      margin: 1,
      minWidth: 120,
      flexGrow: 1
    },
    selectEmpty: {
      marginTop: 2,
    },
  };

class Recipe extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        const recipeData = recipeStorage.get(StockRecipes);
        console.log({recipeData});
        const ingredientData = ingredientsStorage.get(StockIngredients)

        recipeStorage.set(recipeData);

        this.state = {
            recipes: recipeData,
            ingredients: ingredientData,
            createRecipeOpen: false,
            createRecipeGroupOpen: false,
            createRecipeName: "",
            createRecipeCalories: 0,
            createRecipeGroupName: "",
            calories: caloriesStorage.getToday()?.calories || 0,
        };

        console.log({state:this.state})
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        if (countParam)
            this.handleSelect(countParam);

        window.addEventListener('storage', this.handleStorageChange);

        this.context.loading(false);
    }

    handleStorageChange = (event) => {
        console.log('storage', event)
        try {
            if (this.state.calories != caloriesStorage.getToday().calories)
                this.setState({ calories: caloriesStorage.getToday().calories });
            console.log('updated')
        } catch (error) {
            
        }
        // if (event.key === 'syncedValue') {
        //   this.setState({ value: event.newValue || '' });
        // }
    };

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    handleChange = (event) => {
        this.setState({ search: event.target.value });
    };

    handleSelect = (number) => {
        const increment = number;
        const groups = this.state.recipes.groups;
        const group = groups[this.state.recipes.group]
        const collection = group.collection;
        const selection = this.state.recipes.selection;
        if (!collection[selection]) collection[selection] = {};

        console.log('Set calories: ' + collection[selection].calories + increment, collection, increment)
        collection[selection].calories = collection[selection].calories + parseFloat(increment) || 0;
        
        this.sync({groups: this.state.recipes.groups, selection: this.state.recipes.selection, group: this.state.recipes.group})
    };

    handleChangeSelection = (param) => {
        console.log('handleChangeSelection', {param})

        const recipes = this.state.recipes;
        const groups = this.state.recipes.groups;
        const group = groups[this.state.recipes.group]
        const collection = group.collection

        recipes.selection = param.target.value;

        if (param.target.value in collection)
            this.setState({recipes}, this.sync())
        else
            console.error('invalid select', param.target.value)
    }

    handleChangeIngredientSelection = (param) => {
        console.log('handleChangeIngredientSelection', {param})

        const ingredients = this.state.ingredients;
        const groups = ingredients.groups;
        const group = groups[ingredients.group];
        const collection = group.collection

        ingredients.selection = param.target.value;

        if (param.target.value in collection)
            this.setState({ingredients}, this.sync())
        else
            console.error('invalid select', param.target.value)
    }

    handleChangeIngredientGroup = (param) => {
        console.log('handleChangeIngredientGroup', {param})

        const ingredients = this.state.ingredients;
        const groups = ingredients.groups;
        const group = groups[ingredients.group];
        const collection = group.collection

        console.log({ingredients, coll: collection})

        ingredients.group = param.target.value;

        if (param.target.value in collection)
            this.setState({ingredients}, this.sync())
        else
            console.error('invalid select', param.target.value)
    }

    handleChangeRecipeGroup = (param) => {
        console.log('button', {param}, param.target.value, this.state.recipes.groups)

        if (this.state.recipes.groups.hasOwnProperty(param.target.value))
        {
            const recipes = this.state.recipes;
            recipes.group = param.target.value;
            this.setState({recipes}, this.sync())
            //this.sync({groups:this.state.groups, selection:this.state.selection, group: param.target.value})
        }
        else
            console.error('invalid select', param.target.value)
    }

    handleChangeGroup = (param) => {
        console.log('button', {param})

        if (param.target.value in this.state.groups)
            this.sync({groups:this.state.groups, selection:this.state.selection, group: param.target.value})
        else
            console.error('invalid select', param.target.value)
    }

    

    handleAddIngredientToRecipe = () => {
        console.log('handleAddIngredientToRecipe');

        // if (this.state.createRecipeName === "")
        //     return;

        const recipes = this.state.recipes;
        console.log({recipes})
        const groups = recipes.groups;
        const group = groups[this.state.recipes.group]
        console.log({group})
        const collection = group.collection;
        const selection = this.state.recipes.selection;
        const ingredientsList = collection[selection];

        if (!ingredientsList) recipes.groups[this.state.recipes.group].collection[selection] = []

        const ingredients = this.state.ingredients;
        const selectedIngredient = this.state.ingredients.groups[ingredients.group].collection[ingredients.selection];
        selectedIngredient['name'] = ingredients.selection;
        
        // if (this.state.recipes.group != this.state.createRecipeName)
        {
            //recipes.selection = this.state.createRecipeName;
            // const ingredient = {name:"bongar", calories:100}
            console.log('b4',recipes.groups[this.state.recipes.group].collection[selection])
            recipes.groups[this.state.recipes.group].collection[selection].push(selectedIngredient)
            console.log('after',recipes.groups[this.state.recipes.group].collection[selection])
            this.setState({ recipes, createRecipeOpen: false, createRecipeName: "" }, this.sync());
        }
    }

    handleAddIngredient = () => {
        console.log('handleAddIngredient', this.state.createIngredientName)
        // Add Ingredient
        const groups = this.state.groups;
        const group = groups[this.state.group]
        const collection = group.collection;
        const selection = this.state.createIngredientName;
        console.log({selection, group, collection})

        if (!collection[selection]) collection[selection] = {}

        collection[selection].calories = this.state.createIngredientCalories;
        console.log('handleAddIngredient', group, this.state.createIngredientCalories)

        this.sync({groups, selection, group: this.state.group})
        this.setState({ createIngredientOpen: false });

        console.log({groups, selection, group: this.state.group})
    }

    // handleAddGroup = (param) => {
    //     const groups = this.state.recipes.groups;
    //     const cgroup = groups[this.state.recipes.group];

    //     if (this.state.createGroupName === "")
    //         return;

    //     if (this.state.recipes.group != this.state.createGroupName)
    //     {
    //         groups[this.state.createGroupName] = { collection: {}}
    //         this.sync({ groups, selection: this.state.recipes.selection, group: this.state.recipes.group })

    //         this.setState({ createGroupOpen: false });
    //     }
    // }

    handleAddRecipeGroup = (param) => {
        console.log('handleAddRecipeGroup', this.state.createRecipeGroupName)
        
        if (this.state.createRecipeGroupName === "")
            return;

        const recipes = this.state.recipes;
        
        if (this.state.recipes.group != this.state.createRecipeGroupName)
        {
            recipes.group = this.state.createRecipeGroupName;
            recipes.groups[this.state.createRecipeGroupName] = { collection: {}}
            this.setState({ recipes, createRecipeGroupOpen: false, createRecipeGroupName: "" }, this.sync());
        }
    }

    

    handleAddRecipe= (param) => {
        console.log('handleAddRecipe', this.state.createRecipeName)
        
        if (this.state.createRecipeName === "")
            return;

        const recipes = this.state.recipes;
        
        if (this.state.recipes.group != this.state.createRecipeName)
        {
            recipes.selection = this.state.createRecipeName;
            recipes.groups[recipes.group].collection[this.state.createRecipeName] = []
            this.setState({ recipes, createRecipeOpen: false, createRecipeName: "" }, this.sync());
        }
    }

    // clearRecipeGroup = () => {
    //     this.setState({

    //     })
    // }

    handleRemoveRecipeGroup = () => {
        console.log('handleRemoveRecipeGroup')
        // Add Ingredient
        const recipes = this.state.recipes;
        const groups = this.state.recipes.groups;
        const group = groups[this.state.recipes.group]
        console.log({selection: this.state.recipes.collection, group: this.state.recipes.group, collection: this.state.recipes.collection})

        if (group == "")
            return;

        if (recipes.groups[this.state.recipes.group]) 
            delete recipes.groups[this.state.recipes.group];

        recipes.group = ""

        //groups[this.state.recipes.group].collection = collection;

        this.setState({recipes}, this.sync())

        this.setState({createRecipeGroupName: "", createRecipeGroupOpen: false})

        console.log({groups, selection, group: this.state.group})
    }

    handleRemoveRecipe = () => {
        console.log('handleRemoveGroup')
   
        const groups = this.state.recipes.groups;
        const group = groups[this.state.recipes.group]
        console.log({selection: this.state.recipes.collection, group: this.state.recipes.group, collection: this.state.recipes.collection})

        if (group == "")
            return;

        if (group.collection[this.state.createRecipeName]) 
            delete group.collection[this.state.createRecipeName];

        //groups[this.state.recipes.group].collection = collection;

        this.sync({recipes: this.state.recipes, })

        this.setState({createRecipeGroupName: ""})

        console.log({groups, selection, group: this.state.group})
    }

    // handleRemoveGroup = () => {
    //     console.log('handleRemoveGroup')
    //     // Add Ingredient
    //     const groups = this.state.recipes.groups;
    //     const group = groups[this.state.recipes.group]
    //     console.log({selection: this.state.recipes.collection, group: this.state.recipes.group, collection: this.state.recipes.collection})

    //     if (group == "")
    //         return;

    //     if (groups[this.state.createRecipeGroupName]) 
    //         delete groups[this.state.createRecipeGroupName];

    //     groups[this.state.recipes.group].collection = collection;

    //     this.sync({recipes: this.state.recipes, })

    //     this.setState({createGroupName: ""})

    //     console.log({groups, selection, group: this.state.group})
    // }

    sync = () => {
        recipeStorage.set({...this.state.recipes});
        ingredientsStorage.set({...this.state.ingredients})
        this.setState({recipes:this.state.recipes, ingredients:this.state.ingredients});
        console.log("Sync",{...this.state.recipes})
    }

    handleRemoveIngredient = () => {
        console.log('handleRemoveIngredient')
        // Add Ingredient
        const groups = this.state.groups;
        const group = groups[this.state.group]
        const collection = group.collection;
        const selection = this.state.createIngredientName;
        console.log({selection, group, collection})

        if (collection[this.state.createIngredientName]) 
            delete collection[this.state.createIngredientName ];

        groups[this.state.group].collection = collection;

        console.log('handleRemoveIngredient', groups)

        this.sync({groups, selection, group: this.state.group})

        this.setState({createIngredientName: ""})

        console.log({groups, selection, group: this.state.group})
    }


    handleClear = (param) => {
        console.log('button', {param})
        const collection = this.state.collection;
        const selection = this.state.selection;

        if (param == 'clear')
        {
            if (!collection[selection]) collection[selection] = {};
            collection[selection].calories = 0;
            console.log('Set calories: ' + 0, dataStore[selection], selection)
            this.sync({groups, selection, group: this.state.group})
        }
        // else if (param == 'a')
        // {
        //     countStorage.set({counts, counter:0})
        //     this.setState({counter: 0});
        // }
        // else if (param == 'b')
        // {
        //     countStorage.set({counts, counter:1})
        //     this.setState({counter: 1});
        // } 

        this.render();
    };

    handleAddIngredientCalories = () => {
        const increment = this.state.groups[this.state.group].collection[this.state.selection].calories;

        const newValue = this.state.calories + parseFloat(increment) || 0;
        console.log('Set calories: ' + this.state.totalCals + increment, increment)
        caloriesStorage.setToday({'calories': newValue});
        this.setState({ calories: newValue });
    }

    render() {
        document.title = 'ai|Recipes';

        let ingredientsInRecipe = []


        const recipeGroup = this.state.recipes.groups[this.state.recipes.group];
        const groupIsInRecipeGroup = this.state.recipes.groups.hasOwnProperty(this.state.recipes.group);
        const selectedRecipeIsInRecipeGroup = recipeGroup.collection.hasOwnProperty(this.state.recipes.selection);

        
        if (this.state.recipes.group == "")
        {
            ingredientsInRecipe = [];
        }
        else if (selectedRecipeIsInRecipeGroup)
        {
            ingredientsInRecipe = this.state.recipes.groups[this.state.recipes.group].collection[this.state.recipes.selection];
        }

        

        console.log({'rgroup': this.state.recipes.groups[this.state.recipes.group], 'recipe': this.state.recipes.selection})
        console.log({ingredientsInRecipe})

        const ingredeientsFormatted = ingredientsInRecipe.map( x=> { return {...x, title: x.name + " - " + x.calories + "kcal"}})

        console.log({ingredeientsFormatted})

        console.log('render', ingredientsInRecipe)
        console.log('checks', this.state.recipes.groups,this.state.recipes.group )

        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}>
                <Banner text="For experimental purposes only, does use cookies" />
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText>Recipes </HeaderText>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>Select a recipe</SubheaderText>
                        <SelectWithGroup groupLabel="Recipe Group" label="Recipe" data={this.state.recipes} onGroupChange={(group)=>{console.log('onGroupChange', group)}} onSelectionChange={(selection)=>{console.log('onSelectionChange', selection);}}></SelectWithGroup>
                        <p>---</p>
                        <SmallGutter></SmallGutter>
                        {/* <h1 style={{border:'solid 1px lightgrey', padding:'10px'}}><strong>{((this.state.counter == 0)?'a':'b') + ": " + Math.ceil(parseFloat(this.state.counts[this.state.counter]))}</strong></h1> */}
                    </div>

                    {/* First card is recipe group */}
                    <Card>
                        <div style={{ margin: 20, maxWidth: 500}}>
                            <SelectWithMore label="Recipe Group" collection={Object.keys(this.state.recipes.groups)} selection={this.state.recipes.group} handleChangeSelection={this.handleChangeRecipeGroup} handleMore={()=>{this.setState({createRecipeGroupOpen: !this.state.createRecipeGroupOpen})}} ></SelectWithMore>
                        </div>

                        {
                            (this.state.createRecipeGroupOpen)?                                
                                <div style={{ margin: 20, maxWidth: 500, display:'block'}}>
                                    <TextField variant="outlined" label="Recipe Group" type="text" value={this.state.createRecipeGroupName} onChange={(e)=>{this.setState({createRecipeGroupName:e.target.value})}}></TextField>
                                    <Button color="primary" onClick={this.handleAddRecipeGroup} style={{margin:'0px 10px 0px 10px', padding:'15px 20px 15px 20px'}}><AddIcon></AddIcon></Button>
                                    <Button color="primary" variant="outlined" style={{margin:'0px 0px 0px 0px', padding:'15px 10px 15px 10px'}} onClick={this.handleRemoveRecipeGroup}><Delete></Delete></Button>
                                </div>
                                :null
                        }
                    </Card>

                    <LargeGutter></LargeGutter>
                    <LargeGutter></LargeGutter>
                    <LargeGutter></LargeGutter>

                    {/* Second card is recipe name */}
                    {(groupIsInRecipeGroup)?<>
                        <Card>
                            <div style={{ margin: 20, maxWidth: 500}}>
                                <SelectWithMore label="Recipe" collection={Object.keys(this.state.recipes.groups[this.state.recipes.group]?.collection)} selection={this.state.recipes.selection} handleChangeSelection={this.handleChangeSelection} handleMore={()=>{this.setState({createRecipeOpen: !this.state.createRecipeOpen})}}></SelectWithMore>
                            </div>

                            {
                                (this.state.createRecipeOpen)?
                                    <div style={{ margin: 20, maxWidth: 500, display:'block'}}>
                                            <TextField variant="outlined" label="Recipe Name" type="text" value={this.state.createRecipeName} onChange={(e)=>{this.setState({createRecipeName:e.target.value})}}></TextField>
                                            <Button color="primary" onClick={this.handleAddRecipe} style={{margin:'0px 10px 0px 10px', padding:'15px 20px 15px 20px'}}><AddIcon></AddIcon></Button>
                                            <Button color="primary" variant="outlined" style={{margin:'0px 0px 0px 0px', padding:'15px 10px 15px 10px'}} onClick={this.handleRemoveRecipe}><Delete></Delete></Button>
                                        <ol>
                                            
                                        
                                        </ol>
                                        {(selectedRecipeIsInRecipeGroup)?<>
                                            <SubheaderText>Add ingredients</SubheaderText>
                                            {/* A read only group selection and ingredient selection */}
                                            <Card>
                                                <div style={{ margin: 20, maxWidth: 500}}>
                                                    <SelectWithMore label="Ingredient Group" collection={Object.keys(this.state.ingredients.groups)} selection={this.state.ingredients.group} handleChangeSelection={this.handleChangeIngredientGroup} ></SelectWithMore>
                                                </div>
                                            </Card>
                                            <LargeGutter></LargeGutter>
                                            <LargeGutter></LargeGutter>
                                            <Card>
                                                <div style={{ margin: 20, maxWidth: 500}}>
                                                    <SelectWithMore label="Ingredient" collection={Object.keys(this.state.ingredients.groups[this.state.ingredients.group].collection)} handleAdd={this.handleAddIngredientToRecipe} selection={this.state.ingredients.selection} handleChangeSelection={this.handleChangeIngredientSelection}></SelectWithMore>
                                                </div>
                                            </Card>
                                            </>:null}
                                            
                                    </div>
                                    :null
                            }
                        </Card>
                        

                        <LargeGutter></LargeGutter>
                        <LargeGutter></LargeGutter>
                        <LargeGutter></LargeGutter>

                    
                        <LargeGutter></LargeGutter>
                        <LargeGutter></LargeGutter>
                        <LargeGutter></LargeGutter>
                        <SubheaderText>Ingredients in Recipe</SubheaderText>
                        
                        {/* A list of ingredients in the recipe */}

                        <SwipeListTwoWay items={ingredeientsFormatted}></SwipeListTwoWay>
                        <p>{"---"}</p>

                        <ol>
                            { (ingredientsInRecipe).map( (x, index) => (
                                <li key={index}><p>{JSON.stringify(x)}</p></li>
                            ))}
                        </ol>

                        <p>{ingredientsInRecipe.length}</p>
                        <p>{JSON.stringify(ingredientsInRecipe)}</p>
                        
                        <p>Recipes</p>
                        <p>{JSON.stringify(this.state.recipes)}</p>
                        <p>Ingredients</p>
                        <p>{JSON.stringify(this.state.ingredients)}</p>

                    </>:null}
 
                    <Button fullWidth color="primary" style={{margin:'0px 0px 0px 0px', padding:'15px 10px 15px 10px', width:'100%'}} onClick={this.handleAddIngredientCalories}>Add to todays calories: {this.state.calories}</Button>

                    

                    <div style={{ margin: 20, maxWidth: 500}}>
                        
                    </div>
                    
                    
                    
                    
                    {/* <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <FormControl variant="outlined" style={style.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Ingredient</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.selection}
                                onChange={this.handleChangeSelection}
                                label="Ingredient"
                                style={{width:'100%'}}
                                >
                                {Object.keys(this.state.collection).map((ingredient, index) => (
                                    <MenuItem key={index} value={index}>
                                        <Typography>
                                        {ingredient.name} - {ingredient.calories} Calories
                                        </Typography>
                                    </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Button variant="outlined" color="primary" onClick={this.handleCreateNewIngredient} style={{padding:15}}>
                            <AddIcon></AddIcon>
                        </Button>
                    </div> */}
                    <LargeGutter></LargeGutter>
                    
                                        {/* <InputGrid onSelect={this.handleSelect} color={1}/>
                    <InputGrid onSelect={this.handleClear} color={6} columns={3} numbers={['clear','a','b']} /> */}
                </div>
            </div>
        );
    }
}

export default Recipe;
