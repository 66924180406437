
import React, { useState } from 'react';
import { Button, ButtonGroup, makeStyles } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  purpleButton: {
    backgroundColor: 'purple',
    color: 'white',
    '&:hover': {
      backgroundColor: '#7b1fa2', // Darker purple for hover effect
      color: 'cyan'
    },
  },
}));

const AddShortcut = (props) => {
    const classes = useStyles();

    return (
        <LinkButton link={props.shortcut} label={"Add to Apple Shortcuts"}></LinkButton>
    )
}

// props { buttons: ['a','b','c'], selected: '', onSelect: (value)=> {}}
const LinkButton = (props) => {
  const classes = useStyles();
//   const [link] = useState(props.link);

  return (
    <Button
      variant="contained"
      className={classes.purpleButton}
      startIcon={<CloudDownload />}
      component="a"
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      fullWidth
      onClick={(e)=>{console.log({props})}}
    >
      {props.label}
    </Button>
  );
};

export default AddShortcut;