
import React, { Component } from 'react';
import '../../App.css';
import '../../bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from '../../components/Core/ThemeContext';
import { SubheaderText, SmallGutter, HeaderText, LargeGutter } from '../../components/Core/TextComponents';
import { Accordion, Card, Select, TextField, Typography} from '@material-ui/core';
import { Button } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import { Delete } from '@material-ui/icons';
import SelectWithMore from '../../components/Input/SelectWithMore';

class IngredientGroupSelect extends Component {
    static contextType = ThemeContext;

    constructor(props) {
        super(props);

        console.log(this);

        const data = this.props.data;


        // const data = storage.get(StockIngredients)

        // storage.set(data);

        this.state = {
            ...data,
            createIngredientOpen: false,
            createGroupOpen: false,
            createIngredientName: "",
            createIngredientCalories: 0,
            createGroupName: "",
        };

        console.log({state:this.state})
    }

    render() {
        console.log({hello:this});
        return (
            <>
                <Card>
                    <div style={{ margin: 20, maxWidth: 500}}>
                        <SelectWithMore label="Group" collection={Object.keys(this.state.groups)} selection={this.state.group} handleChangeSelection={this.props.handleChangeGroup} handleMore={()=>{this.setState({createGroupOpen: !this.state.createGroupOpen})}} ></SelectWithMore>
                    </div>

                    {
                        (this.state.createGroupOpen)?                                
                            <div style={{ margin: 20, maxWidth: 500, display:'block'}}>
                                <TextField variant="outlined" label="Name" type="text" value={this.state.createGroupName} onChange={(e)=>{this.setState({createGroupName:e.target.value})}}></TextField>
                                <Button color="primary" onClick={this.props.handleAddGroup} style={{margin:'0px 10px 0px 10px', padding:'15px 20px 15px 20px'}}><AddIcon></AddIcon></Button>
                                <Button color="primary" variant="outlined" style={{margin:'0px 0px 0px 0px', padding:'15px 10px 15px 10px'}} onClick={this.props.handleRemoveGroup}><Delete></Delete></Button>
                            </div>
                            :null
                    }
                </Card>

                <Card>
                    <div style={{ margin: 20, maxWidth: 500}}>
                        <SelectWithMore label="Ingredient" collection={Object.keys(this.state.groups[this.state.group].collection)} selection={this.state.selection} handleChangeSelection={this.handleChangeSelection} handleMore={()=>{this.setState({createIngredientOpen: !this.state.createIngredientOpen})}}></SelectWithMore>
                    </div>

                    {
                        (this.state.createIngredientOpen)?
                            <div style={{ margin: 20, maxWidth: 500, display:'block'}}>
                                <TextField fullWidth variant="outlined" label="Name" type="text" value={this.state.createIngredientName} onChange={(e)=>{this.setState({createIngredientName:e.target.value})}}></TextField>
                                <LargeGutter></LargeGutter>
                                <LargeGutter></LargeGutter>
                                <TextField
                                    label="Calories"
                                    type="number" // Set type to number
                                    variant="outlined"
                                    value={this.state.createIngredeintCalories}
                                    onChange={(e)=>{
                                        console.log('Change Calories', e.target.value);
                                        this.setState({createIngredientCalories:e.target.value});
                                    }}
                                    InputLabelProps={{
                                        shrink: true, // Keeps the label shrunk over the input
                                    }}
                                    // Optional: Use fullWidth to stretch it to container width
                                />
                                <Button color="primary" onClick={this.handleAddIngredient} style={{margin:'0px 10px 0px 10px', padding:'15px 20px 15px 20px'}}><AddIcon></AddIcon></Button>
                                <Button color="primary" variant="outlined" style={{margin:'0px 0px 0px 0px', padding:'15px 10px 15px 10px'}} onClick={this.handleRemoveIngredient}><Delete></Delete></Button>
                            </div>
                            :null
                    }
                </Card>
            </>
        )
    }
}

export default IngredientGroupSelect;