import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions
  } from 'react-swipeable-list';
  import './SwipeListTwoWay.css';

  import styled from 'styled-components';

  const colors = {
    accepted: '#4a7b2b',
    deleted: '#bc281e',
    rejected: '#ba772b',
    waitlist: '#296690',
  };

  const ItemContent = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f2f2f2;
  border-style: solid;
  border-color: #e5e5e5;
  border-width: 2px;
  border-top-width: 2px;
  border-bottom-width: 2px;
  color: #000;
  user-select: none;
`;

  const ActionContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  color: #eee;
  user-select: none;
`;
  
  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={(props) => console.info('swipe action triggered')}>
        <ActionContent style={{ backgroundColor: colors.accepted }}>
          Accept
        </ActionContent>
      </SwipeAction>
    </LeadingActions>
  );
  
  const trailingActions = (props) => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => console.info('swipe action triggered')}
      >
        <ActionContent style={{ backgroundColor: colors.deleted }}>
          Delete
        </ActionContent>
      </SwipeAction>
    </TrailingActions>
  );
  

  const SwipeListTwoWay = (props) => {

    const items = props.items;

    console.log({items})

    return (
        <SwipeableList style={{ backgroundColor: '#555878' }}>
            {
                (items).map((item, index) => (
                    <div key={index}>
                        <SwipeableListItem
                            leadingActions={leadingActions()}
                            trailingActions={trailingActions()}>
                            <ItemContent>
                                { item.title }
                            </ItemContent>
                        </SwipeableListItem>
                    </div>
                    
                ))
            }
            
        </SwipeableList>
    )
  }

  export default SwipeListTwoWay;