import React, { useState } from 'react';
import { Button, ButtonGroup, makeStyles } from '@material-ui/core';
import Back from '@material-ui/icons/ArrowBackIos';
import Forward from '@material-ui/icons/ArrowForwardIos';

const InlineButton = (props) => {
    return (
        <Button variant="contained" color='secondary' {...props} style={{display:'inline'}}>{props.children}</Button>
    )
}

// props:
const propsDoc = {
    //setOffset: () => { return offset },
    setOffset: (offset) => {},
    offset: 0
}

const DaySwitch = (props) => {
    const [date, setDate] = new useState('');
    // const [offset, setOffset] = new useState(0);

    const displayDate = () => {
        console.log({offset: props.offset})
        if (props.offset === 0)
            return "Today";
        if (props.offset === -1)
            return "Yesterday"

        let offsetDate = new Date();
        offsetDate.setDate(new Date().getDate() + props.offset)

        return offsetDate.toISOString().split('T')[0];
    }

    const changeOffset = (increment) => {
        if (props.setOffset)
            props.setOffset(props.offset + increment);
    }

    return (
        <>
            <div style={{display:'inline-flex', width:'100%'}}>
                <InlineButton onClick={()=>{changeOffset(-1)}} disabled={props.offset==-7} startIcon={<Back></Back>}></InlineButton>
                <div style={{width:'100%', margin:"0px 12px"}}>
                    <InlineButton variant="outlined" fullWidth> {displayDate()}</InlineButton>
                </div>
                <InlineButton onClick={()=>{changeOffset(1)}} disabled={props.offset==0} startIcon={<Forward></Forward>}></InlineButton>
            </div>
        </>
    )
}

export default DaySwitch;