import React, { Component } from 'react';
import '../../App.css';
import ReactJson from 'react-json-view'
import { Button } from 'react-bootstrap';
import Banner from '../../components/Core/Banner';
import { App as cApp } from '@capacitor/app';

const PageDeleteData = () => {
    const json = {"data":[]};
    console.log('JSON', json)

    for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        let value = localStorage.getItem(key);
        console.log(key, value);
        json.data.push(key);
      }

    return (
        <>
            <Banner text="Warning: deleting data here will mean that all of the data you saved with ai-BIBLE labs tools will be cleared"></Banner>
            <ReactJson src={json} name={"data"}>
            </ReactJson>
            <Button onClick={()=>{
                console.log('click');
                localStorage.clear();
                window.location.reload();
                cApp.triggerListener('appUrlOpen', { url: 'ai-bile://labs' });
                }}>Delete All Data</Button>
        </>
    )
}

export default PageDeleteData;