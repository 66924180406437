import React, { Component } from 'react';
import '../../App.css';
import '../../bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from '../../components/Core/ThemeContext';
import Banner from '../../components/Core/Banner';
import { SubheaderText, HeaderText, LargeGutter, SmallGutter } from '../../components/Core/TextComponents';
import Slider from '../../components/Input/Slider'
import { DailyLocalStorageStore, LocalStorage } from '../../components/Storage/LocalStorage';
import DaySwitch from '../../components/Input/DaySwitch';
import AddShortcut from '../../components/Input/AddShortcut';
import WebOnly from '../../components/Conditional/WebOnly';

const queryParams = new URLSearchParams(window.location.search);
const weightParam = parseFloat(queryParams.get('weight'));

const storage = new DailyLocalStorageStore('aib-weight-log');
const rangeStorage = new LocalStorage('aib-weight-range');

class WeightLog extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        const range = rangeStorage.get([40,120]);
        const data = storage.getToday() || { 'weight': -1.0 };
        const offset = 0;
        const weight = data.weight;

        console.log('weight', {range}, {data})

        this.state = {
            unit: 'kg',
            weight,
            range,
            offset
        };

        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
    }

    setOffset = (offset) => {
       
        let weight = 0;

        if (offset == 0)
            weight = storage.getToday()?.weight;

        if (offset < 0)
        {
            if (!storage.getForDate(offset))
            {
                storage.setForDate(offset, {'weight': 0});
            }
            weight = storage.getForDate(offset)?.weight;
        }

        this.setState({offset, weight});
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);

        console.log(weightParam);

        if (weightParam)
        {
            console.log('wp', weightParam);
            this.handleSliderChange(null, parseFloat(weightParam));
        }
        else if ((!this.state.weight)||(this.state.weight < 0))
        {
            console.log('wp2', weightParam, storage.getYesterday());
            if (storage.getYesterday())
            {
                this.handleSliderChange(null, parseFloat(storage.getYesterday().weight));
            }
        }

        this.context.loading(false);
    }

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    handleSliderChange = (event, newValue) => {
        //console.log('handleSliderChange', newValue)
        this.setState({ weight: newValue});
        storage.setForDate(this.state.offset, {'weight': newValue});
    }

   handleRangeChange = (event, newValue) => {
        console.log('handleRangeChange', newValue)
        this.setState({ range: newValue});
        rangeStorage.set(newValue)
    };

    marks = (min, max) => { return [
        {
          value: min,
          label: min + ' kg',
        },
        {
          value: max,
          label: max + ' kg',
        },
      ];
    }
    
    render() {
        document.title = 'ai|Weight';
        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}
            >
                <WebOnly>
                    <Banner text="For experimental purposes only, uses cookies. Will not store data between sessions if you have private browsing enabled." />
                </WebOnly>
                
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText>Weight Log </HeaderText>
                        <LargeGutter></LargeGutter>
                        
                        <h1 style={{border:'solid 1px lightgrey', padding:'10px'}}><strong>{this.state.weight + " " + this.state.unit}</strong></h1>
                        <LargeGutter></LargeGutter>
                        <SubheaderText>Set your weight</SubheaderText>
                        <LargeGutter></LargeGutter>
                        <Slider
                            getAriaLabel={() => 'Temperature range'}
                            min={this.state.range[0]}
                            max={this.state.range[1]}
                            marks={this.marks(...this.state.range)}
                            step={0.1}
                            value={this.state.weight}
                            onChange={this.handleSliderChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={()=>`${this.state.weight} kg`}
                        />
                        <LargeGutter></LargeGutter>
                        <SubheaderText>
                                Weight Range: <span>{`${this.state.range[0]} - ${this.state.range[1]}`}</span> kg
                        </SubheaderText>
                        <LargeGutter></LargeGutter>
                        <Slider
                            getAriaLabel={() => 'Temperature range'}
                            min={40}
                            max={120}
                            marks={this.marks(40,120)}
                            value={this.state.range}
                            onChange={this.handleRangeChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={()=>`${this.state.range} kg`}
                        />
                    </div>
                    <div style={{ margin: "0px 20px"}}>

                        <SmallGutter></SmallGutter>

                        <DaySwitch offset={this.state.offset} setOffset={this.setOffset}></DaySwitch>
                        <LargeGutter></LargeGutter>
                        <LargeGutter></LargeGutter>
                        <LargeGutter></LargeGutter>

                        {/* <a href='' target="_blank">
                            <Button fullWidth>Add to Apple Shortcuts</Button>
                        </a> */}
                        <WebOnly>
                            <AddShortcut shortcut="https://www.icloud.com/shortcuts/3ee3d742e64a4443b88082d1427ba507"></AddShortcut>
                        </WebOnly>
                    </div>
                </div>
            </div>
        );
    }
}

export default WeightLog;
