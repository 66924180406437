
class LocalStorage {
  constructor(storageKey) {
    this.storageKey = storageKey;
  }

    // Store data with a date key
    set(data) {
      localStorage.setItem(this.storageKey, typeof(data)=='string'?data:JSON.stringify(data));
    }

    get(defaultValue = "") {
      let val = localStorage.getItem(this.storageKey);
      console.log('val', val);
      if (val)
      {
        try {
          val = JSON.parse(val);
        } catch (error) {
          console.log('parse error')
        }
      }
      console.log('valp', val);
      return val || defaultValue;
    }
}

// Stores a single key with a object for each days storage
// Date is formatted as YYYY-MM-DD
class DailyLocalStorageStore {
  constructor(storageKey) {
    this.storageKey = storageKey;
  }

  // Store data with a date key
  set(date, data) {
    let fullData = JSON.parse(localStorage.getItem(this.storageKey));

    if (!fullData)
      fullData = {}

    fullData[date] = data;

    localStorage.setItem(this.storageKey, JSON.stringify(fullData));
  }

  getDateKey(date)
  {
    return date.toISOString().split('T')[0];
  }

  setToday(data)
  {
    return this.set(this.getDateKey(new Date()), data);
  }

  setYesterday(data)
  {
    let yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1)
    return this.set(this.getDateKey(yesterday), data);
  }

  // Retrieve data for a specific date
  get(dateKey) {
    const data = localStorage.getItem(this.storageKey);
    return data ? JSON.parse(data)[dateKey] : null;
  }

  getAll() {
    const data = localStorage.getItem(this.storageKey);
    // const entries = Object.entries(data);
    // entries.sort((a, b) => new Date(a[0]) - new Date(b[0]));
    // const sortedList = entries.map(([date, data]) => ({ date, ...data }));
    // return sortedList;
    return data ? JSON.parse(data) : null;
  }

  getYesterday() {
    let yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1)
    return this.get(this.getDateKey(yesterday));
  }

  setForDate(offset, data)
  {
    let offsetDate = new Date();
    offsetDate.setDate(new Date().getDate() + offset)
    console.log('Setting', offset, offsetDate, this.getDateKey(offsetDate), data)
    return this.set(this.getDateKey(offsetDate), data);
  }


  getForDate(offset) {
    let offsetDate = new Date();
    offsetDate.setDate(new Date().getDate() + offset)
    return this.get(this.getDateKey(offsetDate));
  }

  // Retrieve data for today
  getToday() {
      return this.get(this.getDateKey(new Date()));
  }
}

// Stores a different key each day
class DailyLocalStorageTrail {
    constructor(storageKey) {
      this.storageKey = storageKey;
    }

    // getAllDates() {
    //   // Function to parse date from key
    //   function parseDateFromKey(key) {
    //     const datePart = key.split('_')[1];
    //     const [month, day, year] = datePart.split('-').map(Number);
    //     return new Date(year, month - 1, day);
    //   }

    //   // Retrieve and sort keys
    //   function getSortedKeys() {
    //     const keys = Object.keys(localStorage).filter(key => key.startsWith('weight_'));
    //     keys.sort((a, b) => parseDateFromKey(a) - parseDateFromKey(b));
    //     return keys;
    //   }

    //   // Example usage
    //   const sortedKeys = getSortedKeys();
    //   console.log(sortedKeys);
    // }
  
    // Store data with a date key
    set(date, data) {
      const dateKey = this.getDateKey(date);
      localStorage.setItem(dateKey, JSON.stringify(data));
    }

    setToday(data)
    {
        return this.set(new Date(), data);
    }
  
    // Retrieve data for a specific date
    get(date) {
      const dateKey = this.getDateKey(date);
      const data = localStorage.getItem(dateKey);
      return data ? JSON.parse(data) : null;
    }
  
    // Retrieve data for today
    getToday() {
        return this.get(new Date());
    }

    getYesterday() {
        let yesterday = new Date();
        yesterday.setDate(new Date().getDate() - 1)
        // Subtract one day from the current date
        return this.get(yesterday);
    }
  
    // Utility to generate a date-specific key
    getDateKey(date) {
      const d = new Date(date);
      return `${this.storageKey}-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    }
  
    // Remove data for a specific date
    remove(date) {
      const dateKey = this.getDateKey(date);
      localStorage.removeItem(dateKey);
    }
  
    // Clear all data managed under this storage key
    clearAll() {
      Object.keys(localStorage)
        .filter(key => key.startsWith(`${this.storageKey}-`))
        .forEach(key => localStorage.removeItem(key));
    }
  }

  export {
    DailyLocalStorageTrail,
    DailyLocalStorageStore,
    LocalStorage
  }