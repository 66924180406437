import React, { Component } from 'react';
import '../../App.css';
import { DailyLocalStorageStore } from '../../components/Storage/LocalStorage';
import ReactJson from 'react-json-view'

const storage = new DailyLocalStorageStore('aib-calories-log');

const PageDebug = () => {
    const json = storage.getAll();
    console.log('JSON', json)
    return (
        <>
            <ReactJson src={json} name={"calories"}>
            </ReactJson>
        </>
    )
}

export default PageDebug;