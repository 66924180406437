import React from 'react';

export default function WebOnly(props) {
    return (
        <>
            {window.web_version?
                props.children
            :null}
        </>
    )
}