import React, { Component } from 'react';
import '../../App.css';
import '../../bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from '../../components/Core/ThemeContext';
import Banner from '../../components/Core/Banner';
import { SubheaderText, SmallGutter, HeaderText } from '../../components/Core/TextComponents';
import scales from '../../data/scales.json';
import { ButtonGroup, Button } from '@material-ui/core';
import RadioButtonGroup from '../../components/Input/RadioButtonGroup';
import { GuitarChord } from '../../components/Input/GuitarFretboard';
//import PainoButtonGroup from './components/Input/PianoButtonGroup';

const queryParams = new URLSearchParams(window.location.search);
//onst caloriesParam = parseInt(queryParams.get('calories'));

class Empty extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            contacts: [],
            search: 'John.12.1-John.12.11',
            previoussearch: 'John.12.1-John.12.11',
            unit: 'cal',
            key: 'B',
            scaleType: 'Minor'
        };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        this.context.loading(false);
    }

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    onScaleTypeSelected = ((val)=>{
        this.setState({scaleType: val});
    })

    onScaleSelected = ((val)=>{
        this.setState({scale: val});
    })

    render() {
        const keys = Object.keys(scales);
        const scaleTypes = Object.keys(scales[this.state.key]);
       
        console.log({scaleTypes})
        document.title = 'ai|Emoty';
        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}
            >
                <Banner text="Empty Page Only" />
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText>Scales </HeaderText>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>get each chord and note in a scale</SubheaderText>
                        <SmallGutter></SmallGutter>
                        <RadioButtonGroup buttons={ keys } selected={this.state.scale} onSelect={this.onScaleSelected}></RadioButtonGroup>
                        {/* <PainoButtonGroup></PainoButtonGroup> */}
                        <GuitarChord chord='C'></GuitarChord>
                        <RadioButtonGroup buttons={ scaleTypes } selected={this.state.scaleType} onSelect={this.onScaleTypeSelected}></RadioButtonGroup>
                        <SmallGutter></SmallGutter>
                    </div>
                    <ol>
                        {scaleTypes.map((key)=>(<li key={key}>{key}</li>))}
                    </ol>
                </div>
            </div>
        );
    }
}

export default Empty;
