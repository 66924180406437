import React, { Component } from 'react';
import '../../App.css';
import '../../bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from '../../components/Core/ThemeContext';
import Banner from '../../components/Core/Banner';
import InputGrid from '../../components/Input/InputGrid';
import { SubheaderText, SmallGutter, HeaderText } from '../../components/Core/TextComponents';
import { LocalStorage } from '../../components/Storage/LocalStorage';
import WebOnly from '../../components/Conditional/WebOnly';

const queryParams = new URLSearchParams(window.location.search);
const countParam = parseInt(queryParams.get('count'));
const countStorage = new LocalStorage('aib-counter');

class Counter extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        let total = 0;
        const counts = countStorage.get({counts:[0,0,0], counter:0})

        this.state = {
            contacts: [],
            counts: counts.counts,
            counter: counts.counter,
        };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        if (countParam)
            this.handleSelect(countParam);

        this.context.loading(false);
    }

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    handleChange = (event) => {
        this.setState({ search: event.target.value });
    };

    handleSelect = (number) => {
        const increment = number;
        const counter = this.state.counter;
        const counts = this.state.counts;
        counts[this.state.counter] = this.state.counts[counter] + parseFloat(increment) || 0;
        console.log('Set count: ' + this.state.counts[counter] + increment, this.state.counter, increment)
        countStorage.set({counts, counter})
        this.setState({ counts });
    };

    handleClear = (param) => {
        console.log('button', {param})
        const counter = this.state.counter;
        const counts = this.state.counts;

        if (param == 'clear')
        {
            counts[this.state.counter] = 0;
            console.log('Set count: ' + 0, counter)
            countStorage.set({counts, counter})
            this.setState({counts});
        }
        else if (param == 'a')
        {
            countStorage.set({counts, counter:0})
            this.setState({counter: 0});
        }
        else if (param == 'b')
        {
            countStorage.set({counts, counter:1})
            this.setState({counter: 1});
        } 

        this.render();
    };

    render() {
        document.title = 'ai|Counters';
        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}
            >
                <WebOnly>
                    <Banner text="For experimental purposes only, uses cookies. Will not store data between sessions if you have private browsing enabled." />
                </WebOnly>
                
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText>Dual Counters </HeaderText>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>Add to the number</SubheaderText>
                        <SmallGutter></SmallGutter>
                        <h1 style={{border:'solid 1px lightgrey', padding:'10px'}}><strong>{((this.state.counter == 0)?'a':'b') + ": " + Math.ceil(parseFloat(this.state.counts[this.state.counter]))}</strong></h1>
                    </div>
                    <InputGrid onSelect={this.handleSelect} color={1}/>
                    <InputGrid onSelect={this.handleClear} color={6} columns={3} numbers={['clear','a','b']} />
                </div>
            </div>
        );
    }
}

export default Counter;
