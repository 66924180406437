import React, { Component } from 'react';
import '../../App.css';
import '../../bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from '../../components/Core/ThemeContext';
import Banner from '../../components/Core/Banner';
import InputGrid from '../../components/Input/InputGrid';
import { SubheaderText, SmallGutter, HeaderText, LargeGutter } from '../../components/Core/TextComponents';
import { Accordion, Card, Select, TextField, Typography} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SelectWithMore from '../../components/Input/SelectWithMore';
import SelectAccordion from '../../components/Input/SelectAccordion';
import { Button } from 'react-bootstrap';
import { LocalStorage, DailyLocalStorageStore } from '../../components/Storage/LocalStorage';
import { Delete } from '@material-ui/icons';
import StockIngredients from './StockIngredients.json'
import IngredientGroupSelect from './IngredientGroupSelect';

const queryParams = new URLSearchParams(window.location.search);
const countParam = parseInt(queryParams.get('count'));
const storage = new LocalStorage('aib-ingredient');
const caloriesStorage = new DailyLocalStorageStore('aib-calories-log');

const style = {
    formControl: {
      margin: 1,
      minWidth: 120,
      flexGrow: 1
    },
    selectEmpty: {
      marginTop: 2,
    },
  };

class PageIngredient extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        const data = storage.get(StockIngredients)

        storage.set(data);

        this.state = {
            ...data,
            createIngredientOpen: false,
            createGroupOpen: false,
            createIngredientName: "",
            createIngredientCalories: 0,
            createGroupName: "",
            calories: caloriesStorage.getToday()?.calories || 0,
        };

        console.log({state:this.state})
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        if (countParam)
            this.handleSelect(countParam);

        window.addEventListener('storage', this.handleStorageChange);

        this.context.loading(false);
    }

    handleStorageChange = (event) => {
        console.log('storage', event)
        try {
            if (this.state.calories != caloriesStorage.getToday().calories)
            this.setState({ calories: caloriesStorage.getToday().calories });
            console.log('updated')
        } catch (error) {
            
        }
        // if (event.key === 'syncedValue') {
        //   this.setState({ value: event.newValue || '' });
        // }
    };

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    handleChange = (event) => {
        this.setState({ search: event.target.value });
    };

    handleSelect = (number) => {
        const increment = number;
        const groups = this.state.groups;
        const group = groups[this.state.group]
        const collection = group.collection;
        const selection = this.state.selection;
        if (!collection[selection]) collection[selection] = {};

        console.log('Set calories: ' + collection[selection].calories + increment, collection, increment)
        collection[selection].calories = collection[selection].calories + parseFloat(increment) || 0;
        
        this.sync({groups, selection, group: this.state.group})
    };

    handleChangeSelection = (param) => {
        console.log('button', {param})

        const groups = this.state.groups;
        const group = groups[this.state.group]
        const collection = group.collection;

        if (param.target.value in collection)
            this.sync({groups:this.state.groups, selection:param.target.value, group: this.state.group})
        else
            console.error('invalid select', param.target.value)
    }

    handleChangeGroup = (param) => {
        console.log('button', {param})

        if (param.target.value in this.state.groups)
            this.sync({groups:this.state.groups, selection:this.state.selection, group: param.target.value})
        else
            console.error('invalid select', param.target.value)
    }

    handleAddIngredient = () => {
        console.log('handleAddIngredient', this.state.createIngredientName)
        // Add Ingredient
        const groups = this.state.groups;
        const group = groups[this.state.group]
        const collection = group.collection;
        const selection = this.state.createIngredientName;
        console.log({selection, group, collection})

        if (!collection[selection]) collection[selection] = {}

        collection[selection].calories = this.state.createIngredientCalories;
        console.log('handleAddIngredient', group, this.state.createIngredientCalories)

        this.sync({groups, selection, group: this.state.group})
        this.setState({ createIngredientOpen: false });

        console.log({groups, selection, group: this.state.group})
    }

    handleAddGroup = (param) => {
        const groups = this.state.groups;
        const cgroup = groups[this.state.group];

        if (this.state.createGroupName === "")
            return;

        if (this.state.group != this.state.createGroupName)
        {
            groups[this.state.createGroupName] = { collection: {}}
            this.sync({ groups, selection: this.state.selection, group: this.state.group })

            this.setState({ createGroupOpen: false });
        }
    }

    handleRemoveGroup = () => {
        console.log('handleRemoveGroup')
        // Add Ingredient
        const selection = this.state.selection;
        const groups = this.state.groups;
        const group = groups[this.state.group]
        console.log({selection, group, collection})

        if (group == "")
            return;

        if (groups[this.state.createGroupName]) 
            delete groups[this.state.createGroupName];

        groups[this.state.group].collection = collection;

        this.sync({groups, selection, group: this.state.group})

        this.setState({createGroupName: ""})

        console.log({groups, selection, group: this.state.group})
    }

    sync = (data) => {
        storage.set({...data});
        this.setState({...data});
        console.log({...data})
    }

    handleRemoveIngredient = () => {
        console.log('handleRemoveIngredient')
        // Add Ingredient
        const groups = this.state.groups;
        const group = groups[this.state.group]
        const collection = group.collection;
        const selection = this.state.createIngredientName;
        console.log({selection, group, collection})

        if (collection[this.state.createIngredientName]) 
            delete collection[this.state.createIngredientName ];

        groups[this.state.group].collection = collection;

        console.log('handleRemoveIngredient', groups)

        this.sync({groups, selection, group: this.state.group})

        this.setState({createIngredientName: ""})

        console.log({groups, selection, group: this.state.group})
    }


    handleClear = (param) => {
        console.log('button', {param})
        const collection = this.state.collection;
        const selection = this.state.selection;

        if (param == 'clear')
        {
            if (!collection[selection]) collection[selection] = {};
            collection[selection].calories = 0;
            console.log('Set calories: ' + 0, dataStore[selection], selection)
            this.sync({groups, selection, group: this.state.group})
        }
        // else if (param == 'a')
        // {
        //     countStorage.set({counts, counter:0})
        //     this.setState({counter: 0});
        // }
        // else if (param == 'b')
        // {
        //     countStorage.set({counts, counter:1})
        //     this.setState({counter: 1});
        // } 

        this.render();
    };

    handleAddIngredientCalories = () => {
        const increment = this.state.groups[this.state.group].collection[this.state.selection].calories;

        const newValue = this.state.calories + parseFloat(increment) || 0;
        console.log('Set calories: ' + this.state.totalCals + increment, increment)
        caloriesStorage.setToday({'calories': newValue});
        this.setState({ calories: newValue });
    }

    render() {
        document.title = 'ai|Ingredients';

        const data = storage.get(StockIngredients);

        console.log('render')

        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}>
                <Banner text="For experimental purposes only, does use cookies" />
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText>Ingredients </HeaderText>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>Select an ingredient from a group</SubheaderText>
                        <SmallGutter></SmallGutter>
                        {/* <h1 style={{border:'solid 1px lightgrey', padding:'10px'}}><strong>{((this.state.counter == 0)?'a':'b') + ": " + Math.ceil(parseFloat(this.state.counts[this.state.counter]))}</strong></h1> */}
                    </div>

                    <Card>
                        <div style={{ margin: 20, maxWidth: 500}}>
                            <SelectWithMore label="Group" collection={Object.keys(this.state.groups)} selection={this.state.group} handleChangeSelection={this.handleChangeGroup} handleMore={()=>{this.setState({createGroupOpen: !this.state.createGroupOpen})}} ></SelectWithMore>
                        </div>

                        {
                            (this.state.createGroupOpen)?                                
                                <div style={{ margin: 20, maxWidth: 500, display:'block'}}>
                                    <TextField variant="outlined" label="Name" type="text" value={this.state.createGroupName} onChange={(e)=>{this.setState({createGroupName:e.target.value})}}></TextField>
                                    <Button color="primary" onClick={this.handleAddGroup} style={{margin:'0px 10px 0px 10px', padding:'15px 20px 15px 20px'}}><AddIcon></AddIcon></Button>
                                    <Button color="primary" variant="outlined" style={{margin:'0px 0px 0px 0px', padding:'15px 10px 15px 10px'}} onClick={this.handleRemoveGroup}><Delete></Delete></Button>
                                </div>
                                :null
                        }
                    </Card>

                    <Card>
                        <div style={{ margin: 20, maxWidth: 500}}>
                            <SelectWithMore label="Ingredient" collection={Object.keys(this.state.groups[this.state.group].collection)} selection={this.state.selection} handleChangeSelection={this.handleChangeSelection} handleMore={()=>{this.setState({createIngredientOpen: !this.state.createIngredientOpen})}}></SelectWithMore>
                        </div>

                        {
                            (this.state.createIngredientOpen)?
                                <div style={{ margin: 20, maxWidth: 500, display:'block'}}>
                                    <TextField fullWidth variant="outlined" label="Name" type="text" value={this.state.createIngredientName} onChange={(e)=>{this.setState({createIngredientName:e.target.value})}}></TextField>
                                    <LargeGutter></LargeGutter>
                                    <LargeGutter></LargeGutter>
                                    <TextField
                                        label="Calories"
                                        type="number" // Set type to number
                                        variant="outlined"
                                        value={this.state.createIngredeintCalories}
                                        onChange={(e)=>{
                                            console.log('Change Calories', e.target.value);
                                            this.setState({createIngredientCalories:e.target.value});
                                        }}
                                        InputLabelProps={{
                                            shrink: true, // Keeps the label shrunk over the input
                                        }}
                                        // Optional: Use fullWidth to stretch it to container width
                                    />
                                    <Button color="primary" onClick={this.handleAddIngredient} style={{margin:'0px 10px 0px 10px', padding:'15px 20px 15px 20px'}}><AddIcon></AddIcon></Button>
                                    <Button color="primary" variant="outlined" style={{margin:'0px 0px 0px 0px', padding:'15px 10px 15px 10px'}} onClick={this.handleRemoveIngredient}><Delete></Delete></Button>
                                </div>
                                :null
                        }
                    </Card>


                    <LargeGutter></LargeGutter>
                    <LargeGutter></LargeGutter>
                    <LargeGutter></LargeGutter>


                    <Button fullWidth color="primary" style={{margin:'0px 0px 0px 0px', padding:'15px 10px 15px 10px', width:'100%'}} onClick={this.handleAddIngredientCalories}>Add to todays calories: {this.state.calories}</Button>

                    <div style={{ margin: 20, maxWidth: 500}}>
                        
                    </div>
                    
                    {/* <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <FormControl variant="outlined" style={style.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Ingredient</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.selection}
                                onChange={this.handleChangeSelection}
                                label="Ingredient"
                                style={{width:'100%'}}
                                >
                                {Object.keys(this.state.collection).map((ingredient, index) => (
                                    <MenuItem key={index} value={index}>
                                        <Typography>
                                        {ingredient.name} - {ingredient.calories} Calories
                                        </Typography>
                                    </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Button variant="outlined" color="primary" onClick={this.handleCreateNewIngredient} style={{padding:15}}>
                            <AddIcon></AddIcon>
                        </Button>
                    </div> */}
                    <LargeGutter></LargeGutter>
                    
                                        {/* <InputGrid onSelect={this.handleSelect} color={1}/>
                    <InputGrid onSelect={this.handleClear} color={6} columns={3} numbers={['clear','a','b']} /> */}
                </div>
            </div>
        );
    }
}

export default PageIngredient;
