import React, { Component, useRef } from 'react';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import { App as cApp } from '@capacitor/app';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import BrowseAllBooks from './PageBrowseAllBooks';
import ReadingListSearch from './PageReadingListSearch';
import ExegesisSearch from './PageExegesisSearch';
import ContactUs from './PageContactUs';
import BibleReading from './PageBibleReading';
import Chat from './PageChat';
import Book from './PageBook';
import Sitemap from './PageSitemap';
import Home from './PageHome';
import HelpPage from './PageHelp'
import Privacy from './PagePrivacy';
import ExegesisResult from './PageExegesisResult';
import PeopleResult from './PagePeopleResult';
import PlaceResult from './PagePlacesResult';
import CalorieCounter from './labs/Health/PageCalorieCounter';
import Counter from './labs/Misc/PageCounter';
import DebugStorage from './labs/Misc/PageDebug';
import DeleteData from './labs/Misc/PageDeleteData';
import Weight from './labs/Health/PageWeight';
import Scales from './labs/Music/PageScales';
import Ingredient from './labs/Food/PageIngredient';
import Recipe from './labs/Food/PageRecipe';
import Labs from './PageLabs';
import ChordsFromScale from './labs/Music/PageChordsFromScale';
import NotesFromScale from './labs/Music/PageNotesFromScale';
import Empty from './PageEmpty';
import Plans from './PagePlans';
import Verify from './PagePlansVerify';
import Unsubscribe from './PagePlansUnsubscribe';
import PagePlansGobacklink from './PagePlansGobacklink';
import './App.css';
import './bootstrap-4/css/bootstrap.css';
import logo from './images/aibiblelogo.png';
import { ThemeContext, themes } from './components/Core/ThemeContext';
import ModalLoader from './components/Core/ModalLoader';
import RouteChangeTracker from './RouteChangeTracker'
import { Capacitor } from '@capacitor/core';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { ArrowRight } from '@material-ui/icons';
import { Map } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import MenuBook from '@material-ui/icons/MenuBook';
import ChatBubble from '@material-ui/icons/ChatBubble';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Search from '@material-ui/icons/Search';
import Help from '@material-ui/icons/Help';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Network } from '@capacitor/network';
import * as Swetrix from 'swetrix'
import FlaskIcon from '@material-ui/icons/LocalHospital';

window.API_BASE = 'https://d2ec36mx9v0bh8.cloudfront.net';

window.getVerseURL = (verse) => {
    return (window.web_version)?`/read/${verse}`:`/reader?verse=${verse}`;
}

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 7000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const web_version = (Capacitor.getPlatform()==='web');

window.web_version = web_version;
window.show_footer = true;

// const TRACKING_ID = "UA-138966130-2"; // YOUR_OWN_TRACKING_ID
// const MEASUREMENT_ID = "G-9GMZ38C5JB"; // GA4 Property ID
const PROJECT_ID = "frPSChzQBszC"; // Swetrix Project ID
const PROJECT_ID_ANDROID = "GRfVoFTuslGZ"; // Swetrix Project ID ANDROID
const PROJECT_ID_IOS = "jil1UKyHMq4Y"; // Swetrix Project ID IOS

if (!process.env.TESTMODE)
{
    if (Capacitor.getPlatform()==='ios')
        Swetrix.init(PROJECT_ID_IOS);
    else if (Capacitor.getPlatform()==='android')
        Swetrix.init(PROJECT_ID_ANDROID);
    else
        Swetrix.init(PROJECT_ID);

    Swetrix.trackViews();

    // ReactGA.initialize(MEASUREMENT_ID, {
    //     gaOptions: {
    //         storeGac: false,
    //         anonymizeIp: true
    //     }
    // });    
}

const handleDeepLink = (event) => {
    console.log('DeepLink Received: ', event)
    const url = event.url;
    console.log('DeepLink Decoded: ', url)
    
    if (url) {
      // Strip the base URL from the incoming deep link URL
      const path = url.replace('ai-bible://', ''); // Replace 'myapp://' with your app's scheme
      
      // Automatically route to the corresponding path
      //this.props.history.push(path);
      window.location = "/" + path;
    }
  };

document.addEventListener('deviceready', () => {
    console.log('deviceReady');
    cApp.addListener('appUrlOpen', (event) => {
        console.log('App opened with URL:', event.url);
        handleDeepLink(event);
    });

    cApp.addListener('appStateChange', ({ isActive }) => {
        console.log('App state changed. Is active?', isActive);
    });
});

const checkAppLaunchUrl = async () => {
    const { url } = await cApp.getLaunchUrl();
    console.log('React: App opened with URL: ' + url);
};

checkAppLaunchUrl();

const routes = [
    {
        path: '/',
        name: 'Browse',
        Component: BrowseAllBooks,
        mobile: true,
        TabIcon: MenuBook,
    }, {
        path: '/reader',
        hidden: true,
        // hidden: web_version,
        // mobile: true,
        name: 'Reader',
        Component: BibleReading,
        // TabIcon: MenuBook,
    },
    , {
        path: '/read/:verse',
        hidden: true,
        // hidden: web_version,
        // mobile: true,
        name: 'Reader',
        Component: BibleReading,
        // TabIcon: MenuBook,
    }, 
    {
        path: '/search',
        name: 'Search',
        Component: ReadingListSearch,
        //hidden: true,
        // mobile: true,
        // TabIcon: Search,
    }, 
    {
        path: '/Chat',
        name: 'Chat',
        Component: Chat,
        mobile: true,
        TabIcon: ChatBubble,
    },{
        path: '/exegesis',
        name: 'Exegesis',
        //Component: ExegesisSearch,
        Component: Chat,
        hidden: true
        // mobile: true,
        // TabIcon: FingerprintIcon,
    }, {
        path: '/Privacy',
        name: 'Privacy',
        Component: Privacy,
    },{
        path: '/Plans',
        name: 'Plans',
        Component: Plans,
        mobile: true,
        TabIcon: ListAltIcon
    },{
        path: '/plans/verify',
        name: 'Verify',
        hidden: true,
        Component: Verify
    },{
        path: '/plans/unsubscribe',
        name: 'Unsubscribe',
        hidden: true,
        Component: Unsubscribe
    },{ 
        path: '/plans/goback',
        name: 'Goback',
        hidden: true,
        Component: PagePlansGobacklink
    },{
        path: '/help',
        hidden: false,
        name: 'Help',
        Component: HelpPage,
    },{
        path: '/labs',
        hidden: false,
        name: 'Labs',
        Component: Labs,
        TabIcon: FlaskIcon,
        mobile: true
    }
    , {
        path: '/contact',
        name: 'Contact',
        Component: ContactUs,
    }, {
        path: '/exegete',
        hidden: true,
        name: 'ExegesisResult',
        //Component: ExegesisResult,
        Component: Chat,
    }, {
        path: '/people',
        hidden: true,
        name: 'PeopleResult',
        Component: PeopleResult,
    }, {
        path: '/person/:person',
        hidden: true,
        name: 'PeopleResult',
        Component: PeopleResult,
    }, {
        path: '/places',
        hidden: true,
        name: 'PlaceResult',
        Component: PlaceResult,
    }, {
        path: '/location/:place',
        hidden: true,
        name: 'PlaceResult',
        Component: PlaceResult,
    }, {
        path: '/book',
        hidden: true,
        name: 'Book',
        Component: Book,
    }, {
        path: '/sitemap',
        hidden: true,
        name: 'Sitemap',
        Component: Sitemap,
    }, {
        path: '/empty',
        hidden: true,
        name: 'Empty',
        Component: Empty,
    },  {
        path: 'labs/scales',
        hidden: true,
        name: 'Scales',
        Component: Scales,
    }, {
        path: '/labs/chords',
        hidden: true,
        name: 'ChordsFromScale',
        Component: ChordsFromScale,
    }, {
        path: '/labs/notes',
        hidden: true,
        name: 'NotesFromScale',
        Component: NotesFromScale,
    },
    {
        path: '/labs/debug',
        hidden: true,
        name: 'Debug',
        Component: DebugStorage,
    },
    {
        path: '/labs/weight',
        hidden: true,
        name: 'Weight',
        Component: Weight,
    },{
        path: '/labs/calories',
        hidden: true,
        name: 'CalorieCounter',
        Component: CalorieCounter,
    }, 
    ,{
        path: '/labs/counter',
        hidden: true,
        name: 'Counter',
        Component: Counter,
    }, 
    ,{
        path: '/labs/ingredient',
        hidden: true,
        name: 'Ingredient',
        Component: Ingredient,
    }, {
        path: '/labs/recipe',
        hidden: true,
        name: 'Recipe',
        Component: Recipe,
    }, {
        path: '/labs/deleteData',
        hidden: true,
        name: 'DeleteData',
        Component: DeleteData,
    }, 
];

class App extends Component {
    state = {
        contacts: [],
        expanded: false,
        loading: true,
        path: '/',
        menuBarHeight: 78,
        spacerHeight: 78,
        insets: {
            bottom:0,
            left:0,
            right:0,
            top:0
        }
    }

    fixedMenuBarRef = React.createRef();

    spacerRef = React.createRef();

    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.setInsets();
        document.body.style.backgroundColor = '#fdfdff';
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    setInsets() {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
            this.setState({insets});
        });
    }

    setLoading(loading) {
        this.setState({ loading });
    }

    navLinkHandleClick() {
        console.log('Clicked');
        this.setLoading(true);
    }

    componentDidMount() {
        console.log('App componentDidMount');
        this.setInsets();
        //console.log('insets', this.state.insets);
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); 
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        console.log('Removing appUrlOpen listener...');
        cApp.removeListener('appUrlOpen', handleDeepLink);
    }

    handleResize() {
        let newHeight = this.fixedMenuBarRef?.current?.getBoundingClientRect().height;
        if (newHeight > 90) newHeight = 84; // Hard fix this, no idea why this is 386 sometimes on safari

        this.setState({
            menuBarHeight: newHeight,
            spacerHeight: this.spacerRef?.current?.getBoundingClientRect().height
        }, ()=> {
            
        })

        //console.log("resize", this.state.menuBarHeight, newHeight);

        this.setInsets();
    }

    toggle() {
        console.log('Navbar Toggled');
        this.setState((state, props) => ({ expanded: !state.expanded }));
    }

    render() {
        const theme = createTheme({
            typography: {
                fontFamily: ['Pontano+Sans', 'Montserrat', 'Titillium Web', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
            },
        });

        themes.light.loading = this.setLoading;
        themes.dark.loading = this.setLoading;

        // this.handleResize();
        // const bounds = this.fixedMenuBarRef?.current?.getBoundingClientRect();

        //console.log('fixedMenuBar', this.state.menuBarHeight, this.state.spacerHeight);

        return (
            <div style={{ position: 'relative' }}>
                <AlertProvider template={AlertTemplate} {...options}>
                <div>
                    <ThemeContext.Provider value={themes.light}>
                        <ThemeProvider theme={theme}>
                            <Router>
                                <RouteChangeTracker/>
                                {
                                    // Change NavBar configuration based on whether it is a mobile or web platform
                                    (window.web_version)?
                                        <>
                                            <Navbar
                                            className="color-nav"
                                            style={{
                                                paddingLeft: 12, paddingRight: 12, paddingTop: 0, paddingBottom: 8, minHeight: 78
                                            }}
                                            bg="light"
                                            expand="lg"
                                            fixed="top"
                                            
                                            onToggle={this.toggle.bind(this)}
                                            expanded={this.state.expanded}
                                            ref={this.fixedMenuBarRef}
                                            >
                                            <Navbar.Brand href="#home" className="navbarbranding">
                                                <img src={logo} alt="ai-BIBLE" className="navbarlogo ml-2" />
                                            </Navbar.Brand>

                                            <Navbar.Collapse id="basic-navbar-nav ml-auto">
                                                <Nav className="ml-auto">
                                                    {routes.map((route, index) => ((route.hidden !== true) && (
                                                        <div key={index}>
                                                            <Nav.Link
                                                                as={NavLink}
                                                                to={route.path}
                                                                className="titletext"
                                                                activeClassName="active"
                                                                onClick={
                                                                    () => {
                                                                        if (route.path !== this.state.path) {
                                                                            this.setState({ path: route.path, expanded: !this.state.expanded });
                                                                            this.navLinkHandleClick.bind(this);
                                                                        }
                                                                    }
                                                                }
                                                                exact
                                                            >
                                                                {route.name}
                                                            </Nav.Link>
                                                        </div>
                                                    )))}
                                                </Nav>
                                            </Navbar.Collapse>

                                            <div className="navbartogglezone">
                                                <Navbar.Toggle />
                                                <img src={logo} alt="ai-BIBLE" className="navbarlogo2" />
                                            </div>

                                        </Navbar>
                                        <div className="navbarheightguess" style={{height:this.state.menuBarHeight}} ref={this.spacerRef}></div>
                                    </>:
                                    <>
                                        {window.show_footer?<>
                                        <div className="navbarheightguess" id="hello" style={{height:this.state.insets.top, color: 'black'}} ref={this.spacerRef}></div>
                                        <Navbar
                                            className="color-nav"
                                            style={{
                                                paddingLeft: 12, paddingRight: 12, paddingTop: 0, paddingBottom: 8, minHeight: 54, border: '1px solid #ccc'
                                            }}
                                            bg="light"
                                            fixed="bottom"
                                            onToggle={this.toggle.bind(this)}
                                            ref={this.fixedMenuBarRef}
                                            >
                                                <Navbar.Collapse id="basic-navbar-nav ml-auto">
                                                <Nav className="w-100" style={{marginLeft: 'auto', marginRight:'auto'}}>
                                                    <div className=" d-flex flex-row justify-content-around w-100">
                                                    {routes.map((route, index) => ((route.hidden !== true) && (route.mobile === true) && (
                                                        <NavItem key={index}>
                                                            <Nav.Link
                                                                as={NavLink}
                                                                to={route.path}
                                                                className="nav-link"
                                                                activeClassName="active"
                                                                style={{
                                                                    color: (route.path !== this.state.path)?'#aaa':'#026',
                                                                    paddingTop: 10,
                                                                    paddingBottom: 0,
                                                                    margin: 0
                                                                }}
                                                                onClick={
                                                                    () => {
                                                                        if (route.path !== this.state.path) {
                                                                            this.setState({ path: route.path, expanded: !this.state.expanded });
                                                                            this.navLinkHandleClick.bind(this);
                                                                        }
                                                                    }
                                                                }
                                                                exact
                                                            >
                                                                <div className="row d-flex flex-column justify-content-center align-items-center" style={{
                                                                    padding: 0,
                                                                    maxHeight: '7vh'
                                                                }}>
                                                                    <route.TabIcon style={{
                                                                        maxHeight: '2.3vh'
                                                                    }}/>
                                                                    <div style={{
                                                                        fontSize: '2.1vh'
                                                                    }}>{route.name}</div>
                                                                </div>
                                                            </Nav.Link>
                                                        </NavItem>
                                                    )))}
                                                    </div>
                                                </Nav>
                                                </Navbar.Collapse>

                                                <div className="navbartogglezone">
                                                <Navbar.Toggle />
                                            </div>
                                        </Navbar>
                                        </>:<>
                                            <div className="navbarheightguess" id="hello" style={{height:this.state.insets.top, color: 'black'}} ref={this.spacerRef}></div>
                                            <div id="browsebar" style={{backgroundColor:'#F9F9F9'}}>
                                                <a href='/' style={{color:'#007', height:44, fontSize:16, padding:5}}>&nbsp;&lt;&lt;&nbsp;browse&nbsp;</a>
                                                {(window.history.length >= 1)?
                                                    <button onClick={() => window.history.back()} style={{color:'#007', border: 'none', backgroundColor:"#FFFF", height:32, fontSize:16, padding:5}}>&nbsp;&lt;&nbsp;back</button>
                                                    : null
                                                }
                                                {(window.history.length != 0)?
                                                    <button onClick={() => window.history.forward()} style={{color:'#007', border: 'none', backgroundColor:"#FFFF", height:32, fontSize:16, padding:5}}>&nbsp;&nbsp;forward&nbsp;&gt;</button>
                                                    : null
                                                }
                                            </div>
                                        </>}
                                    </>   
                                }

                                {/* <div style={{height:this.state.menuBarHeight, backgroundColor:"#F0F"}}>

                                </div> */}
                                
                                {routes.map(({ path, Component }) => (
                                    <Route key={path} exact path={path}>
                                        {({ match }) => (

                                            <div className="example">
                                                {(match != null) ? <Component /> : null}
                                            </div>

                                        )}
                                    </Route>
                                ))}
                            </Router>
                        </ThemeProvider>
                    </ThemeContext.Provider>
                </div>
                
                {/* <div style={{
                    position: 'absolute',
                    background: 'linear-gradient(165deg, #ececf1 -200%, #fdfdff 100%)',
                    top: 0,
                    left: 0,
                    minHeight: '100vh',
                    minWidth: '100vw',
                    zIndex: 3000,
                    pointerEvents: 'none',
                    opacity: this.state.loading ? 1 : 0,
                    display: this.state.loading ? '' : 'none',
                }}
                />
                <ModalLoader loading={this.state.loading} /> */}
                </AlertProvider>
                
            </div>
        );
    }
}

export { App };
