
import React, { useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Typography, Select } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';


const style = {
    formControl: {
        margin: 0,
        minWidth: 120,
        flexGrow: 1
    },
    selectEmpty: {
        marginTop: 0,
    },
};

// <SelectWithAdd label="" collection={[]} selection="" handleChangeSelection={(param)=>{}} handleAdd={()=>{} ></SelectWithAdd>

const SelectWithMore = (props) => {

    console.log('SelectWithMore:', props.selection, props.label)
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <FormControl variant="outlined" style={style.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">{props.label}</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={props.selection}
                onChange={props.handleChangeSelection}
                label={props.label}
                style={{width:'100%'}}
                >
                {props.collection.map((key, index) => (
                    <MenuItem key={index} value={key}>
                        <Typography>
                            {key}
                        </Typography>
                    </MenuItem>
                    ))}
            </Select>
        </FormControl>
        { (props.handleMore)?
            <Button variant="outlined" color="primary" onClick={props.handleMore} style={{padding:"15px 0px"}}>
                <MoreIcon style={{margin:"2px"}}></MoreIcon>
            </Button>:null
        }
        { (props.handleSettings)?
            <Button variant="outlined" color="primary" onClick={props.handleSettings} style={{padding:"15px 0px"}}>
                <SettingsIcon style={{margin:"2px"}}></SettingsIcon>
            </Button>:null
        }
        { (props.handleAdd)?
            <Button variant="outlined" color="primary" onClick={props.handleAdd} style={{padding:"15px 0px"}}>
                <AddIcon style={{margin:"2px"}}></AddIcon>
            </Button>:null
        }
         { (props.handleEdit)?
            <Button variant="outlined" color="primary" onClick={props.handleAdd} style={{padding:"15px 0px"}}>
                <EditIcon style={{margin:"2px"}}></EditIcon>
            </Button>:null
        }
        </div>
    )
}

export default SelectWithMore;